import React from 'react';
import { Link } from 'react-router-dom';
import location from '../img/locationqr.jpg'


const Footer = () => {
    const handleWhatsAppClick = () => {

        window.open('https://wa.me/+917800955889', '_blank');
    };
    return (

        <>
            <footer className="footer">
                <div className="footer-container">
                    <div className="footer-section">
                        <h3>About</h3>
                        <p>DR. PRATEEK K. MEHROTRA</p>
                        <p>Consultant Endocrine & Breast Cancer Surgeon Breast & Endocrine Surgery Services, Hind Institute of Medical Sciences, Safedabad, Barabanki, India.</p>
                    </div>
                    <div className="footer-section">
                        <h3>Services</h3>
                        <ul>
                            <Link to='/Thyroid' style={{ color: 'black' }}><li>Thyroid Disease, Goiters & Thyroid Cancer</li></Link>
                            <Link to='/DiabeticFoot' style={{ color: 'black' }}><li>Diabetic Foot Problems</li></Link>
                            <Link to='/Parathyroid' style={{ color: 'black' }}><li>Parathyroid & Metabolic Bone Diseases</li></Link>
                            <Link to='/AdrenalTumours' style={{ color: 'black' }}><li>Adrenal Tumours</li></Link>
                            <Link to='/Endocrine' style={{ color: 'black' }}><li>Endocrine Pancreatic</li></Link>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Contact</h3>
                        <p>Email: <a href="mailto:drprateekmehrotra@yahoo.com">drprateekmehrotra@yahoo.com</a></p>
                        <p>Phone: <a href="tel:+917800955889">+91-7800955889</a></p>
                        <p> <a href='https://maps.app.goo.gl/yPnQ3m3WMxeTQeud9' style={{ color: 'black' }}>Address: 9-B Tilak Marg, Dali Bagh, Opposite Narmada Bhawan & Near Kidzee School, Lucknow</a>  </p>

                        <ul className="social-links">
                            <li><a href="#"><i className="fab fa-facebook-f"></i> Facebook</a></li><br />
                            <li><a href="#"><i className="fab fa-twitter"></i> Twitter</a></li><br />
                            <li><a href="#"><i className="fab fa-instagram"></i> Instagram</a></li><br />
                            <li><a href="#"><i className="fab fa-youtube"></i> YouTube</a></li>
                        </ul>


                    </div>
                    <div className="footer-section">
                        <h3>Find  Us On Google</h3>
                        
                        <img src={location} />
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; {new Date().getFullYear()} Dr. Prateek K. Mehrotra. All Rights Reserved.</p>
                </div>
            </footer>

            <button className="whatsapp-button" onClick={handleWhatsAppClick}>
                <i className="fab fa-whatsapp"></i>
            </button>

        </>
    );
};

export default Footer;
