import React, { useEffect, useState } from 'react';

function VisitorCounter() {
    const [totalVisits, setTotalVisits] = useState(0);
    const [todayVisits, setTodayVisits] = useState(0);
    const [monthVisits, setMonthVisits] = useState(0);
    const [yearVisits, setYearVisits] = useState(0);

    useEffect(() => {
        // Retrieve visit data from local storage
        const visitData = JSON.parse(localStorage.getItem('visitData')) || {};

        // Increment total visits by 1
        visitData.total = (visitData.total || 0) + 1;

        // Check today's date
        const today = new Date().toDateString();

        // Check if today's visit count should be incremented
        if (visitData.todaysDate !== today) {
            visitData.todaysDate = today;  // Update today's date
            visitData.todayCount = 1;       // Reset today's count to 1 for a new day
        } else {
            visitData.todayCount = (visitData.todayCount || 0) + 1; // Increment today's count
        }

        // Check for monthly visits
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        if (visitData.month === undefined || visitData.monthYear !== `${currentMonth}-${currentYear}`) {
            visitData.monthCount = 1; // Reset for a new month
            visitData.month = currentMonth;
            visitData.monthYear = `${currentMonth}-${currentYear}`;
        } else {
            visitData.monthCount += 1; // Increment monthly count
        }

        // Check for yearly visits
        if (visitData.year === undefined || visitData.year !== currentYear) {
            visitData.yearCount = 1; // Reset for a new year
            visitData.year = currentYear;
        } else {
            visitData.yearCount += 1; // Increment yearly count
        }

        // Save updated visit counts to local storage
        localStorage.setItem('visitData', JSON.stringify(visitData));

        // Update state with the latest counts
        setTotalVisits(visitData.total);
        setTodayVisits(visitData.todayCount || 0);
        setMonthVisits(visitData.monthCount || 0);
        setYearVisits(visitData.yearCount || 0);
    }, []);

    return (
        <div className="visit-counter">
            <h2>Visit Counts</h2>
            <div className="counter-item">
                <strong>Total Visits:</strong> {totalVisits}
            </div>
            <div className="counter-item">
                <strong>Today's Visits:</strong> {todayVisits}
            </div>
            <div className="counter-item">
                <strong>This Month's Visits:</strong> {monthVisits}
            </div>
            <div className="counter-item">
                <strong>This Year's Visits:</strong> {yearVisits}
            </div>
        </div>
    );
};

export default VisitorCounter;
