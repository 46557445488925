import React from 'react'
import Footer from '../component/Footer'
import diabetic from '../img/diabeticfoot.jpg'
import foot1 from '../img/pdf (1).jpg'
import foot2 from '../img/pdf (2).jpg'

const DiabeticFoot = () => {
    return (
        <>
            <div className='headings'>
                <h1>Diabetic Foot Problems</h1>
            </div>

            <div className='diabetic-foot-content'>
                <p>
                    Diabetic foot problems arise due to prolonged high blood sugar levels, which can lead to nerve damage
                    (neuropathy) and poor circulation. These conditions reduce the body's ability to heal wounds and sense
                    pain, increasing the risk of infection. Common diabetic foot issues include ulcers, infections, and
                    even the need for amputation if left untreated. Early diagnosis and proper foot care can significantly
                    reduce complications.
                </p>

                <img src={diabetic} />
                <img src={foot1} />
                <img src={foot2} />

            </div>

            <Footer />
        </>
    )
}

export default DiabeticFoot
