import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar as BootstrapNavbar, Nav, NavDropdown } from 'react-bootstrap';
import '../css/navbar.css';

const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false); 
    };

    return (
        <BootstrapNavbar expand="lg" expanded={expanded} className="navbar-light">
            <Container>
                <BootstrapNavbar.Brand as={Link} to="/" onClick={handleNavItemClick}>
                    DR. PRATEEK K. MEHROTRA
                </BootstrapNavbar.Brand>
                <BootstrapNavbar.Toggle
                    aria-controls="navbarSupportedContent"
                    onClick={() => setExpanded(!expanded)}
                />
                <BootstrapNavbar.Collapse id="navbarSupportedContent">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/" onClick={handleNavItemClick}>Home</Nav.Link>

                        <NavDropdown title="About" id="about-dropdown" className="custom-dropdown-title">
                            <NavDropdown.Item as={Link} to="/Myabout" onClick={handleNavItemClick}>About Dr. Prateek</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Myabout" onClick={handleNavItemClick}>About Clinic </NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Our Services" id="services-dropdown" className="custom-dropdown-title">
                        <NavDropdown.Item as={Link} to="/BreastCancer" onClick={handleNavItemClick}>Breast Cancer & Benign Breast Disease</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Thyroid" onClick={handleNavItemClick}>Thyroid Disease, Goiters & Thyroid Cancer</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/DiabeticFoot" onClick={handleNavItemClick}>Diabetic Foot Problems</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Parathyroid" onClick={handleNavItemClick}>Parathyroid & Metabolic Bone Diseases</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/AdrenalTumours" onClick={handleNavItemClick}>Adrenal Tumours</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Endocrine" onClick={handleNavItemClick}>Endocrine Pancreatic</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="About Breast Cancer" id="breast-cancer-dropdown" className="custom-dropdown-title">
                            <NavDropdown.Item as={Link} to="/BreastCancer" onClick={handleNavItemClick}>Breast Cancer</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/BreastRelated" onClick={handleNavItemClick}>Breast Related Problem</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Media" id="media-dropdown" className="custom-dropdown-title">
                            <NavDropdown.Item as={Link} to="/Photoes" onClick={handleNavItemClick}>Photos</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Videos" onClick={handleNavItemClick}>Videos</NavDropdown.Item>

                        </NavDropdown>

                        <Nav.Link as={Link} to="/Contact" onClick={handleNavItemClick}>Contact</Nav.Link>
                    </Nav>
                </BootstrapNavbar.Collapse>
            </Container>
        </BootstrapNavbar>
    );
};

export default Navbar;
