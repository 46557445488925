import React from 'react'
import about from '../img/abouts.jpg'
import { Link } from 'react-router-dom'


const Section = () => {
    return (
        <div className='section-up'>

            <div className='consultant'>
                <h1>DR. PRATEEK K. MEHROTRA
                </h1>
                <h2>Breast & Endocrine Surgeon
                </h2>

                <hr style={{ border: 'none', height: '3px', backgroundColor: 'red', margin: '20px 0' }} />


                <p> 🟢 M.Ch (Endocrine Surgery), MRCS(Edinburgh), FAES, FLCS, DNB(Surg), MNAMS</p>
                <p style={{ color: ' #2980b9' }}> 🟢 Breast & Endocrine Surgeon

                    Ass. Prof, Division of Breast & Endocrine Surgery Services, Dept of Surgery,
                    Hind Institute of Medical Sciences, Safedabad, Barabanki, India
                </p>

                <h2>DEGREES & EDUCATION PROFILE </h2>
                <p>🟢 <b>M.Ch (Endocrine Surgery):</b> <span style={{ color: ' #2980b9' }}> Department of Endocrine & Breast Surgery, Sanjay Gandhi Post Graduate Institute of Medical Sciences (SGPGIMS) Lucknow, India</span></p>

                <p>🟢  <b>M.R.C.S. Edinburgh:</b>	 <span style={{ color: ' #2980b9' }}>Royal College of Surgeons of Edinburgh, England.</span></p>

                <p>
                    <b> 🟢 Diplomat of National Board (DNB), General Surgery:</b> <span style={{ color: ' #2980b9' }}>Department of General & Laparoscopic Surgery, Sir Ganga Ram Hospital, New Delhi, India.</span>
                </p>

                <p> <b> 🟢 MBBS Bachelor of Medicine & Surgery:</b>  <span style={{ color: ' #2980b9' }}>G.S.V.M. Medical College, Kanpur (C.S.J.M. Kanpur University) India</span></p>

                <p>🟢Endocrine Surgery training at Nagoya University Hospital, Nagoya Japan </p>
                <p>🟢Endocrine Surgery training at Royal North Shore Hospital, Sydney Australia </p>
                <p>🟢Diabetic foot training at Amrita Institute of Medical Sciences, Kochi,  Kerala. </p>
              


                <Link to='/About'>  <button className='btn btn-primary'>Read More</button>  </Link>

            </div>


            <div className='consultant2'>
                <img src={about} />
            </div>

        </div>
    )
}

export default Section
