import React from 'react'
import Footer from '../component/Footer'
import indo from '../img/endocrine.jpg' // Importing the endocrine image

const Endocrine = () => {
    return (
        <>
            <div className='headings'>
                <h1>Endocrine Pancreatic </h1>
            </div>

            <div className='endocrine-content'>
                <p>
                    Endocrine pancreatic tumors, also known as neuroendocrine tumors (NETs), are rare types of tumors that form in 
                    the hormone-producing cells of the pancreas. These tumors can be functional, meaning they produce excess hormones, 
                    or non-functional, causing no hormonal symptoms. Depending on the type of hormone produced, symptoms can vary 
                    and may include weight loss, digestive issues, or blood sugar imbalances. Early detection and specialized treatment 
                    are essential for managing endocrine pancreatic tumors effectively.
                </p>

                <img 
                    src={indo} 
                    alt="Endocrine Pancreatic Illustration" 
                    style={{ width: '100%', height: 'auto', marginTop: '20px' }} 
                />
            </div>

            <Footer />
        </>
    )
}

export default Endocrine
