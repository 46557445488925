import React from 'react'
import Footer from '../component/Footer'
import breast from '../img/cancerbreast.jpg' // Importing breast cancer image

const BreastCancer = () => {
    return (
        <>
            <div className='headings'>
                <h1>Breast Cancer</h1>
            </div>

            <div className='breast-cancer-content'>
                <p><b>Be aware of Breast Cancer: Early detection saves lives!</b></p>
                <p>
                    Breast cancer is the most common cancer affecting women across the world, including India.
                    Worldwide, female breast cancer has now surpassed lung cancer as the most commonly diagnosed
                    cancer. Nearly one new case of breast cancer is being diagnosed every four minutes worldwide.
                    According to research, 1 in every 28 women is likely to get affected by the disease during their lifetime.
                    While breast cancer occurs almost entirely in women, around 1-2% men are likely to get affected, too.

                </p>

                <p>Because of the rising incidence, mortality rates, and advanced stages of the cancer at diagnosis, it is
                    essential to spread awareness about breast cancer literacy. This goes a long way in diagnosing the disease
                    at an early stage leading to favorable treatment outcomes.</p>

                <p><b>Risk factors
                </b></p>

                <p>Only 7-10% of breast cancers are hereditary, rest can be prevented by lifestyle changes. 1 out of 22 urban
                    women, and 1 out of 60 rural women in India are at risk. The risk of developing breast cancer increases
                    with age.</p>

                <p>Following females are at a higher risk if they have any of the following personal or family history-</p>
                <p>If a first degree relative is diagnosed with breast or ovarian cancer</p>
                <p>If a woman is diagnosed with one sided breast cancer</p>
                <p>If a male first degree relative is diagnosed with breast cancer</p>
                <p>If one is having Ashkenazi Jewish ancestry</p>

                <p>There are certain non-modifiable risk factors like advancing age of an individual, gender, any family
                    history of breast or ovarian cancer in first degree relatives, individuals of Ashkenazi Jewish ancestry,
                    and age of menarche (onset of periods).
                </p>
                <p>It is important to focus on certain risk factors which are within the control of an individual (modifiable
                    risk factors). To name of few of them: controlling obesity, restricting the use of tobacco & alcohol,
                    restricting the use of hormonal birth control pills (long term use is a risk factor), menopausal females
                    taking hormone replacement therapy, age of mother at first child birth and duration of breast feeding
                    (breast feeding is protective).
                </p>

                <p><b>Warning signs of breast cancer</b></p>
                <p>Females should be aware of the symptoms of breast cancer. If they notice any of the following symptoms,
                    they should timely consult a specialist Breast surgeon for further evaluation and proper management:</p>

                <p>A lump in the breast or underarm (most common symptom)</p>
                <p>Change in the size, shape or appearance of the breast</p>
                <p>An inverted or retracted nipple</p>
                <p>Peeling, scaling or crusting of the area of skin surrounding the nipple (i.e. areola) or breast skin</p>
                <p>Redness or pitting of the breast skin</p>
                <p>Bloody or clear discharge from the nipple</p>
                <p>Swelling, warmth, redness or darkening on the breast</p>
                <p>Itchy, scaly sore or rash on the nipple</p>
                <p>It is important to highlight that pain is usually NOT a symptom of breast cancer in the initial stages.
                    Therefore painless development of a lump in the breast is at times more alarming than a painful
                    symptomatology.
                </p>
                <p><b>Tips for Breast Cancer prevention & Early diagnosis
                </b></p>
                <p>Prevention of breast cancer can be done by leading a healthy and disciplined life style like- eating a lowfat diet rich in fruits and vegetables, exercising regularly to maintain a healthy weight and keep stress at
                    bay. Proper breast feeding reduces the risk of breast cancer and avoiding smoking & excessive alcohol
                    consumption are helpful in risk reduction.</p>
                <p>Early detection of breast cancer can be done through regular screening. It includes:</p>
                <p>Breast self-examination (BSE) to be done on a monthly basis by ladies themselves. One should be aware of the changes in the contours or normal feel of your breasts</p>
                <p>Periodical clinical examination by a specialist doctor to detect lump or nodes, especially in high-risk individuals</p>
                <p>Annual screening mammography (get a baseline scan at 35 years of age and then annually after 40 years of age)</p>
                <p>These modest changes can help in the prevention and also detect the disease in its early stages, which improves outcomes and survival rates.</p>
                <p>How to do a Breast Self-Examination (BSE)</p>
                <p>The best time to do a monthly breast self-exam is about 3 to 5 days after your period starts. Do it at the same time every month. Your breasts are not as tender or lumpy at this time in your monthly cycle. If you have gone through menopause, do your exam on the same day every month.</p>
                <p>Begin by lying on your back. It is easier to examine all breast tissue if you are lying down.</p>
                <p>• Place your right hand behind your head</p>
                <p>• With the middle fingers of your left hand, gently yet firmly press down using small motions to examine the entire right breast</p>
                <p>• Next, sit or stand; feel your armpit, because breast tissue goes into that area</p>
                <p>• Gently squeeze the nipple, checking for discharge</p>
                <p>• Repeat the process on the left breast </p>
                <p>In case one notices any changes in the breast, or any of the features mentioned above, the lady should
                    discuss it with her close family relatives and seek timely medical advice by specialist surgeon dealing
                    with breast diseases.</p>

                <p><b>Diagnosis</b></p>
                <p>Once a lady comes to a breast clinic, she undergoes a clinical examination and depending on the
                    symptoms and clinical findings further investigations are planned. The tests may include a radiological
                    test like Ultrasound or Mammography of the breast, some blood tests and if required, a pathological test
                    (needle test or core biopsy) for microscopic analysis. </p>

                <p><b>Treatment</b></p>
                <p>If breast cancer has been diagnosed in a women, the treatment includes a combination of surgery,
                    chemotherapy, immunotherapy, targeted therapy, radiotherapy, and hormonal therapy. Not all modalities
                    are required for each case of breast cancer and the sequence of treatment needs to be individualized,
                    depending on patient factors, tumour factors and stage of the disease.</p>
                <p>If the patients is diagnosed in the early stage, then breast need not be removed in toto and patients can
                    be offered breast conservation surgery (to save the breast). Even in advanced cases of stage IV cancers,
                    palliative treatment has good results, which helps to prolong survival & improve quality of life.</p>

                <p><b>Certain Myths about Breast cancer</b></p>
                <p>There are certain myths about breast cancer which require clarifications. They are as follows:</p>
                <p><strong>Myth:</strong> Cancer spreads by FNAC / Biopsy / Surgical intervention.<br />
                    <strong>Reality:</strong> Needle test / core biopsy are the investigation of choice for diagnosis, and studies do not show any evidence of spread by these interventions.</p>

                <p><strong>Myth:</strong> Mammogram leads to radiation exposure and causes cancer.<br />
                    <strong>Reality:</strong> Annual mammograms have minimal radiation exposure and are within safety limits, and do not contribute to causing cancers.</p>

                <p><strong>Myth:</strong> Breast cancer affects older women only.<br />
                    <strong>Reality:</strong> There is a change in the demographic profile of breast cancer patients in the last 3-4 decades, with around 40% of patients being diagnosed before the 5th decade of life.</p>

                <p><strong>Myth:</strong> If you have a risk factor, you will get cancer.<br />
                    <strong>Reality:</strong> Having a risk factor only marginally increases your chances of cancer.</p>

                <p>All we need is awareness of breast cancer to save lives before cancer gets a hold over the body!</p>













                <img
                    src={breast}
                    alt="Breast Cancer Awareness"
                    style={{ width: '100%', height: 'auto', marginTop: '20px' }}
                />
            </div>

            <Footer />
        </>
    )
}

export default BreastCancer
