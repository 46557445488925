import React from 'react'
import Footer from '../component/Footer'
import goiter from '../img/goiter.jpg'
import para from '../img/thoriedpara.jpg'


const Thyroid = () => {
    return (
        <>
            <div className='headings'>
                <h1>Thyroid Disease, Goiters & Thyroid Cancer</h1>
            </div>

            <div className='thyroid-content'>


<img src={para}/>


                <p><b>Primary Hyperparathyroidism: The not so rare disease</b></p>
                <p>
                    Primary hyperparathyroidism (PHPT) occurs when parathyroid glands produce excess of parathyroid
                    hormone (PTH), leading to hypercalcemia.
                </p>
                <p><b>PHPT presents in three clinical phenotypes:</b> overt target organ involvement, mild asymptomatic
                    hypercalcemia, and normo-calcemic hyperparathyroidism. PHPT may be present in familial settings like
                    MEN type I & II, familial isolated HPT and hyperparathyroidism jaw tumour syndrome. The known
                    offending genes are CCND1 and MEN1-menin gene. PHPT is caused by solitary adenomas (80-85%),
                    four-gland hyperplasia (10–15%), multiple adenomas (5%) and parathyroid cancer (1%). </p>
                <p><b>Symptoms:</b> Symptoms include bone & joint pain, muscle weakness, tiredness, depression, recurrent
                    non-healing fractures or recurrent renal stones. In severe cases hypercalcemic crisis may present as
                    nausea & vomiting, abdominal pain, confusion and shock.
                </p>
                <p><b>Diagnosis: Diagnosis is biochemical.</b> Asymptomatic HPT are picked up on routine calcium testing.
                    Hypercalcemia, hypophosphatemia and raised PTH levels are pathognomic. PHPT can be distinguished
                    from secondary and tertiary HPT by its different biochemical profile. PTH levels should be measured
                    with either an ‘intact’ second- generation PTH assay or a third-generation assay.
                </p>
                <p><b>Pre-operative localisation:</b> Diagnosis of PHPT is purely biochemical and parathyroid imaging has no
                    role in the diagnosis of PHPT. Imaging studies assist the parathyroid surgeon in identifying the anatomic
                    position of abnormal gland(s) when planning parathyroidectomy, including ectopic locations. Negative
                    imaging, which is frequent in multi-glandular PHPT or concomitant nodular thyroid diseases, is not
                    inconsistent with the diagnosis of PHPT and does not preclude surgical cure</p>

                <p>Ultrasound has a high sensitivity for localizing parathyroid adenomas. Other radiological tests include
                    Parathyroid 4-D CT and MRI. Functional radionuclide imaging with 99mTc-sestamibi scan or Dual-isotope
                    subtraction scans using MIBI & 123I or 99mTc help in localizing the adenomas. Selective PTH venous
                    sampling (PVS) is reserved for persistent or recurrent PHPT cases. Newer localisation modalities include
                    choline PET/CT.

                </p>

                <p>Surgery remains the first-line treatment for PHPT and is recommended for all symptomatic patients.
                    Asymptomatic patients who are  50 yrs; Ca++ 1mg/dL above normal; bone density T-score −2.5 with
                    increased risk of fractures and having recurrent kidney stones should undergo surgery. Surgery may </p>

                <p>vary from focused parathyroidectomy to unilateral or bilateral neck exploration with intraoperative PTH
                    monitoring. Single gland removal, three & a half gland parathyroidectomy or total parathyroidectomy with
                    forehand auto-transplantation may be done as per the clinical setting of PHPT. </p>

                <p><b>Parathyroidectomy is the definitive treatment for PHPT.</b> When performed by experienced endocrine
                    surgeons, the procedure has success rates of 95% and a low rate of complications and recurrence.</p>




                <img src={goiter} />
            </div>

            <Footer />
        </>
    )
}

export default Thyroid
