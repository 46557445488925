import React from 'react'
import Footer from '../component/Footer'
import foot from '../img/parathroid.jpg'

const Parathyroid = () => {
    return (
        <>
            <div className='headings'>
                <h1>Parathyroid & Metabolic Bone Diseases</h1>
            </div>

            <div className='parathyroid-content'>
                <p>
                    The parathyroid glands, located behind the thyroid, are responsible for regulating calcium levels in the blood.
                    Parathyroid disorders often result in imbalanced calcium levels, which can cause various symptoms, including
                    muscle weakness, fatigue, bone pain, and kidney stones. The most common parathyroid conditions are
                    hyperparathyroidism, where too much parathyroid hormone (PTH) is produced, and hypoparathyroidism, which results
                    in low levels of PTH. Proper diagnosis and treatment are essential to managing these conditions effectively.
                </p>


                <img src={foot} />

            </div>


            <Footer />
        </>
    )
}

export default Parathyroid
