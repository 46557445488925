import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


import throid from '../img/thiroid.jpg'
import dibfoot from '../img/dibfoot.jpg'
import paratho from '../img/para.jpg'
import adrenal from '../img/adrenal.jpg'
import indrocin from '../img/endrocin.jpg'
import breast from '../img/breastcancer.jpg'
import breastcancer from '../img/breastcancer.jpg'

const Services = () => {
    const [visibleContent, setVisibleContent] = useState(null);

    const handleClick = (content) => {
        setVisibleContent(content);
    };

    const renderContent = () => {
        switch (visibleContent) {
            case 'thyroid':
                return (
                    <Card className="content-card">
                        <Card.Body>
                        <h1> Thyroid Diseases</h1>
                            <img src={throid} />
                            
                            Thyroid disease is a medical condition that affects the thyroid gland, which produces hormones that regulate many of the body's functions.

                        </Card.Body>
                    </Card>
                );
            case 'diabetic':
                return (
                    <Card className="content-card">
                        <Card.Body>
                        <h1>Diabetic Foot Problems</h1>
                            <img src={dibfoot} />
                            
                            Diabetic foot problems can occur when high blood sugar damages the nerves and blood vessels in the feet. Some common foot problems associated with diabetes include:
                        </Card.Body>
                    </Card>
                );
            case 'parathyroid':
                return (
                    <Card className="content-card">
                        <Card.Body>
                        <h1>Parathyroid</h1>
                            <img src={paratho} />
                           
                            The parathyroid glands are a group of four small glands that produce and secrete parathyroid hormone (PTH), which regulates calcium levels in the blood
                        </Card.Body>
                    </Card>
                );
            case 'adrenal':
                return (
                    <Card className="content-card">
                        <Card.Body>
                        <h1>Adrenal Tumours</h1>
                            <img src={adrenal} />
                            
                            Adrenal tumors are growths that can develop in the adrenal glands, which are located on top of the kidneys.
                        </Card.Body>
                    </Card>
                );
            case 'pancreatic':
                return (
                    <Card className="content-card">
                        <Card.Body>
                        <h1> Endocrine Pancreatic</h1>
                            <img src={indrocin} />
                          
                            Together, they comprise the endocrine pancreas. Diabetes mellitus, a heterogeneous disorder, is the most common disease of the endocrine pancreas.
                        </Card.Body>
                    </Card>
                );

            case 'Breast':
                return (
                    <Card className="content-card">
                        <Card.Body>
                        <h1>Breast Cancer</h1>
                            <img src={breast} />
                            
                            Breast cancer is a disease that occurs when breast tissue cells grow out of control and form tumors:
                        </Card.Body>
                    </Card>
                );

            case 'Breastprolem':
                return (
                    <Card className="content-card">
                        <Card.Body>
                        <h1>Breast Problems</h1>
                            <img src={breastcancer} />
                            Most women experience breast changes at some time. Your age, hormone levels, and medicines you take may cause lumps, bumps, and discharges (fluids that are not breast milk)
                        </Card.Body>
                    </Card>
                );


            default:
                return null;
        }



    };

    return (
        <>

            <div className='servid'>
                <h1> Our Services</h1>
                <h2>Click Button To View </h2>
            </div>

            <div className="services-container">
                <Button variant="primary" onClick={() => handleClick('thyroid')}>
                    Thyroid Diseases
                </Button>
                <Button variant="primary" onClick={() => handleClick('diabetic')}>
                    Diabetic Foot Problems
                </Button>
                <Button variant="primary" onClick={() => handleClick('parathyroid')}>
                    Parathyroid
                </Button>
                <Button variant="primary" onClick={() => handleClick('adrenal')}>
                    Adrenal Tumours
                </Button>
                <Button variant="primary" onClick={() => handleClick('pancreatic')}>
                    Endocrine Pancreatic
                </Button>

                <Button variant="primary" onClick={() => handleClick('Breast')}>
                    Breast Cancer
                </Button>
                <Button variant="primary" onClick={() => handleClick('Breastprolem')}>
                    Breast Problem
                </Button>

                <div className="mt-3">{renderContent()}</div>
            </div>



        </>
    );
}

export default Services;
