import React from 'react';
import thumb from '../img/youtube.jpg'; 
import video1 from '../img/youvideo.mp4';


const Videosection = () => {
    return (
        <>
            <div className='servid'>
                <h1>Our Videos</h1>
                <h2>Click To Play Video</h2>
            </div>
            <div className='videos'>
                <div className='video'>
                    <video controls poster={thumb}>
                        <source src={video1} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='video'>
                    <video controls poster={thumb}>
                        <source src={video1} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='video'>
                    <video controls poster={thumb}>
                        <source src={video1} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='video'>
                    <video controls poster={thumb}>
                        <source src={video1} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </>
    );
};

export default Videosection;
