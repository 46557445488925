import React from 'react'
import Footer from '../component/Footer'
import adrenal from '../img/adrenal.jpg' // Importing adrenal image

const AdrenalTumours = () => {
    return (
        <>
            <div className='headings'>
                <h1>Adrenal Tumours</h1>
            </div>

            <div className='adrenal-tumours-content'>
                <p>
                    Adrenal tumours are abnormal growths in the adrenal glands, located above the kidneys. These tumours can be 
                    either benign (non-cancerous) or malignant (cancerous). The adrenal glands produce important hormones that 
                    regulate metabolism, immune response, and stress responses. Some adrenal tumours can cause excessive 
                    hormone production, leading to conditions such as Cushing’s syndrome or hyperaldosteronism. Proper diagnosis 
                    and treatment are crucial for managing adrenal tumours, and surgical removal is often the recommended option 
                    in certain cases.
                </p>

                <img 
                    src={adrenal} 
                    alt="Adrenal Tumours Illustration" 
                    style={{ width: '100%', height: 'auto', marginTop: '20px' }} 
                />
            </div>

            <Footer />
        </>
    )
}

export default AdrenalTumours
